import { library, dom } from '@fortawesome/fontawesome-svg-core';

import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

import {
	faArrowDown,
	faChevronLeft,
	faArrowLeft,
	faArrowRight
} from '@fortawesome/pro-regular-svg-icons';

import {
	faBars,
} from '@fortawesome/pro-light-svg-icons';


library.add(
	faBars,
	faFacebookF,
	faInstagram,
	faArrowDown,
	faChevronLeft,
	faArrowLeft,
	faArrowRight
);

dom.watch();
